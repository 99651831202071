import React, { useState, useEffect } from "react"
import classNames from "classnames"

import AllSizedHelmet from "../components/AllSizedHelmet"
import HeaderAuthentication from "../components/_V2/HeaderAuthentication"
import FooterCompact from "../components/_V2/FooterCompact"
import RegistrationForm from "../components/_V2/RegistrationForm"
import SmsCodeForm from "../components/_V2/SmsCodeForm"
import RegistrationFacts from "../components/_V2/RegistrationFacts"
import RegistrationSuccess from "../components/_V2/RegistrationSuccess"
import RegistrationFailed from "../components/_V2/RegistrationFailed"

import pageStyles from "../styles/_V2/pagesStyles/styles.module.scss"
import styles from "./styles/_V2/registration.module.scss"
import { convertUrlParamsToObject } from "../utils"
import { checkAuth } from "../apiRequests/auth"
import { createRegistryErrorAmoOffer } from "../apiRequests/registration"
import { signupFormChangeSendEvent } from "../gtm-events"

import config from "../config"
import BusinessTypeForm from "../components/_V2/BusinessTypeForm";

export default function PageRegistration() {
	const [step, setStep] = useState(1)
	const [createdCloudData, setCreatedCloudData] = useState({})
	const [isCloudCreated, setIsCloudCreated] = useState(false)
	//const [smsCode, setSmsCode] = useState("")

	const setStepWithPayload = (newStep, payload) => {
		if (payload?.smsCode) {
			setSmsCode(payload.smsCode)
		}

		setStep(newStep)
	}

	const defaultErrors = {
		name: "Введите корректное имя",
		email: "Введите корректный email",
		phone: "Введите корректный номер телефона",
	}

	const [formData, setFormData] = useState({
		name: {
			value: "",
			errorMessage: "",
			isFilled: false,
		},
		email: {
			value: "",
			errorMessage: "",
			isFilled: false,
		},
		phone: {
			value: "",
			errorMessage: "",
			isFilled: false,
		},
		promocode: {
			value: "",
			errorMessage: "",
			isFilled: true,
		},
		businessType: {
			value: "",
			errorMessage: "",
			isFilled: false,
		},
		city: {
			value: "",
			errorMessage: "",
			isFilled: false,
		},
		status: {
			value: "",
			errorMessage: "",
			isFilled: false,
		},
		places: {
			value: "",
			errorMessage: "",
			isFilled: false,
		},
	})

	const [isShowPromocode, setIsShowPromocode] = useState(false)
	const [ycToken, setYcToken] = useState(null)

	const handleInputChange = (name, value, isFilled, errorMessage) => {
		if (!errorMessage) {
			errorMessage = defaultErrors[name]
		}

		setFormData({
			...formData,
			[name]: {
				errorMessage: !isFilled ? errorMessage : "",
				value,
				isFilled,
			},
		})

		try {
			signupFormChangeSendEvent()
		} catch {
			console.log("gtm не найден")
		}
	}

	useEffect(() => {
		if (typeof window !== "undefined") {
			const { promoCode } = convertUrlParamsToObject(window.location.search)

			if (promoCode) {
				handleInputChange("promocode", promoCode, true)
				setIsShowPromocode(true)
			} else {
				// Обработка открытия страницы регистрации по ссылке
				// с сайта белорусских интеграторов quickresto.by
				const registryDataFromUrlMatch = /^#\/?signup(\?.+)/.exec(
					window.location.hash
				)

				if (registryDataFromUrlMatch) {
					const queryObj = convertUrlParamsToObject(registryDataFromUrlMatch[1])

					setFormData({
						...formData,
						// Если имя не задано в параметрах URL,
						// использовать прочерк по умолчанию
						name: {
							value: queryObj.name || formData.name.value || "_",
							errorMessage: "",
							isFilled: queryObj.name || formData.name.value,
						},
						email: {
							value: queryObj.email || formData.email.value || "_",
							errorMessage: "",
							isFilled: queryObj.email || formData.email.value,
						},
						phone: {
							value: queryObj.phone || formData.phone.value || "_",
							errorMessage: "",
							isFilled: queryObj.phone || formData.phone.value,
						},
						promocode: {
							value: queryObj.promoCode || formData.promocode.value || "_",
							errorMessage: "",
							isFilled: queryObj.promoCode || formData.promocode.value,
						},
						businessType: {
							value: queryObj.businessType || formData.businessType.value || "_",
							errorMessage: "",
							isFilled: queryObj.businessType || formData.businessType.value,
						},
						city: {
							value: queryObj.city || formData.city.value || "_",
							errorMessage: "",
							isFilled: queryObj.city || formData.city.value,
						},
						status: {
							value: queryObj.status || formData.status.value || "_",
							errorMessage: "",
							isFilled: queryObj.status || formData.status.value,
						},
						places: {
							value: queryObj.places || formData.places.value || "_",
							errorMessage: "",
							isFilled: queryObj.places || formData.places.value,
						},
					})

					setIsShowPromocode(!!queryObj.promoCode)
				}
			}

			// Если пользователь авторизован перекинуть его на "личный кабинет"
			checkAuth().then(isUserAuthorized => {
				if (isUserAuthorized) {
					window.location.href = "/personal/"
				}
			})
		}
	}, [])

	const goToError = () => {
		setStep("error")
	}

	const registryErrorAmoOffer = async () => {
		const resultObj = {}

		Object.entries(formData).forEach(
			([key, fieldState]) => (resultObj[key] = fieldState.value)
		)

		await createRegistryErrorAmoOffer(resultObj)
	}

	return (
		<main
			className={classNames(pageStyles.pageWrapper, styles.registration, {
				[styles.registrationForms]: step < 3,
			})}
		>
			<AllSizedHelmet>
				<title>
					Регистрация в системе автоматизации кафе и ресторанов – Quick Resto
				</title>

				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, maximum-scale=5"
				/>
				<meta httpEquiv="Content-Type" content="text/html; charset=UTF-8"/>
				<meta httpEquiv="ScreenOrientation" content="autoRotate:disabled"/>

				<meta
					name="description"
					content="Зарегистрируйтесь в Quick Resto и задействуйте все возможности для роста продаж и увеличения прибыли. Бесплатный пробный период — 14 дней"
				/>

				<meta property="og:url" content="https://quickresto.ru/registration/"/>
				<meta
					property="og:title"
					content="Регистрация в системе автоматизации кафе и ресторанов – Quick Resto"
				/>
				<meta
					property="og:description"
					content="Зарегистрируйтесь в Quick Resto и задействуйте все возможности для роста продаж и увеличения прибыли. Бесплатный пробный период — 14 дней"
				/>
				<meta property="og:type" content="website"/>
				<meta
					property="og:image"
					content="https://quickresto.ru/static/devicesImg-e47757e6c1c5dc0cde08eea13b30253a.png"
				/>

				<link rel="canonical" href="https://quickresto.ru/registration/"/>
			</AllSizedHelmet>

			<div className={styles.registration__wrapper}>
				<HeaderAuthentication />

				<div className={styles.registration__content}>
					{step === 1 && (
						<RegistrationForm
							isShowPromocode={isShowPromocode}
							setIsShowPromocode={setIsShowPromocode}
							setYcToken={setYcToken}
							formData={formData}
							onChangeFormField={handleInputChange}
							setStep={setStep}
						/>
					)}

					{step === 2 && (
						<BusinessTypeForm
							formData={formData}
							onChangeFormField={handleInputChange}
							setStep={setStep}
							ycToken={ycToken}
							setCreatedCloudData={setCreatedCloudData}
							setIsCloudCreated={setIsCloudCreated}
						/>
					)}


					{/*{step === 2 && (*/}
					{/*	<SmsCodeForm*/}
					{/*		netErrorHandler={goToError}*/}
					{/*		setStep={setStepWithPayload}*/}
					{/*		formData={formData}*/}
					{/*		recaptchaToken={recaptchaToken}*/}
					{/*		setRecaptchaToken={setRecaptchaToken}*/}
					{/*	/>*/}
					{/*)}*/}

					{step === 3 && (
						<RegistrationFacts
							netErrorHandler={goToError}
							setStep={setStep}
							formData={formData}
							setCreatedCloudData={setCreatedCloudData}
							isCloudCreated={isCloudCreated}
						/>
					)}

					{step === 4 && (
						<RegistrationSuccess
							createdCloudData={createdCloudData}
							phone={formData.phone.value}
						/>
					)}

					{step === "error" && <RegistrationFailed setStep={setStep} />}
				</div>

				<FooterCompact />
			</div>
		</main>
	)
}
