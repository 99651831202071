import * as React from "react"
import { SVGProps } from "react"

const ArrowDown = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="14"
		height="8"
		viewBox="0 0 14 8"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path d="M11.875 1.5625C10.0392 3.39831 8.83581 4.60169 7 6.4375L2.125 1.5625" stroke="#7D828C"
			  stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
)

export default ArrowDown
