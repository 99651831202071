import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import styles from "./styles.module.scss"
import { externalLinks } from "../../../pages-data/_V2/common/links"

export default {
	img: (
		<StaticImage
			className={styles.registrationFailed__img}
			src="./assets/registration-failed.png"
			breakpoints={[288, 570, 811, 1622, 674, 1348]}
			sizes="(max-width: 500px) 288px, (max-width: 1319px) 811px, (min-width: 1320px) 674px"
			alt="Ошибка регистрации"
			objectFit="contain"
			placeholder="blurred"
			quality={90}
		/>
	),
	title: "Что-то пошло не так, обратитесь в техподдержку",
	support: {
		link: externalLinks.supportBot.href,
		text: "Обратиться в техподдержку",
	},
	stepBack: {
		text: "Вернуться назад",
	},
}
