import classNames from "classnames"
import React, { useState, useEffect, useRef } from "react"
import styles from "./styles.module.scss"
import ValidityOkIcon from "../../../assets/_V2/svg/ValidityOkIcon"
import { ACTION_STATUSES } from "../RequestCallModal/RequestCallModal"
import ArrowDown from "../../../assets/_V2/svg/ArrowDown";

interface Props {
	inputId: string
	className?: string
	inputTitle?: string
	value: string
	actionStatus: ACTION_STATUSES
	errorMessage: string
	onInputChange: (value: string, isInputFilled: boolean) => void
	extraProps?: { [key: string]: string | boolean }
	type?: string
	isHideOK?: boolean
	options: []
}

export const SelectInput: React.FC<Props> = props => {
	const [showTitle, setShowTitle] = useState(true)
	const [showOptions, setShowOptions] = useState(false)
	const [selectedItem, setSelectedItem] = useState(props.value)

	// Показываем title, если есть автофокус
	useEffect(()=> {
		if (props.extraProps?.autoFocus) {
			setShowTitle(true)
		}
	}, [])

	const checkIfInputFilled = (inputValue: string) => {
		return inputValue.length >= 2
	}

	const onFocus = () => {
		setShowTitle(true)
	}

	const handleSelect = () => {
		showOptions ? setShowOptions(false) : setShowOptions(true)
	}

	const handleOption = (option: string) => {
		setSelectedItem(option)
		props.onInputChange(option, true)
		setShowOptions(false)

	};

	const field = useRef(null)

	return (
		<>
			<label
				htmlFor={props.inputId}
				className={classNames(
					styles.input, props.className, {
				})}
			>
				<div
					className={classNames(styles.inputContainer, {
						[styles.inputContainer_error]: props.errorMessage,
						[styles.inputContainer_focused]: showOptions,
					})}
					onClick={handleSelect}
				>
					<div className={styles.inputWithTitle}>
						{(props.value || showTitle) && (
							<p className={classNames(
								styles.inputTitle,
								{[styles.inputTitle__focused]: showOptions,
								[styles.inputTitle__small]: selectedItem || props.value }
							)}>
								{props.inputTitle}
							</p>
						)}
						{
							<span className={styles.inputValue}>
								{selectedItem}
							</span>
						}
					</div>

							<div
								className={classNames(
									styles.icon,
									styles.icon__arrow,
									{[styles.icon__arrow__up]: showOptions}
								)}
							>
								<ArrowDown />
							</div>

				</div>
				{showOptions && (
					<div className={styles.selectOptions}>
						{props.options.map(option => (
							<span onClick={() => handleOption(option)} className={styles.selectOptions__item}>{option}</span>
						))}
					</div>
				)}
			</label>
		</>
	)
}
