import React, {useState, useRef, useEffect, SetStateAction, Dispatch} from 'react'
import classNames from 'classnames'
import styles from './styles.module.scss'

interface Props {
    stepsCount: number
    currentStep: number
    onChangeStep: Dispatch<SetStateAction<number>>
}

function StepList(props: Props) {
  const steps = Array.from({ length: props.stepsCount }, (_, i) => i + 1)

    const handleChangeStep = (step: number) => {
        if (props.currentStep - 1 !== 0) {
            props.onChangeStep(step)
        }
    }

  return (
    <ol className={styles.stepList}>
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          <li
              onClick={() => handleChangeStep(step)}
              className={classNames(
                  styles.stepList__item,
                  props.currentStep === step ? styles.active : styles.inactive,
                  {[styles.filled]: step < props.currentStep }
              )}>
            {step}
          </li>
          {index < steps.length - 1 && <span className={styles.stepList__dotline} />}
        </React.Fragment>
      ))}
    </ol>
  )
}

export default StepList
