import classNames from "classnames"
import React, { useState, useEffect, useRef } from "react"
import styles from "./styles.module.scss"
import ValidityOkIcon from "../../../assets/_V2/svg/ValidityOkIcon"
import { ACTION_STATUSES } from "../RequestCallModal/RequestCallModal"
import { AddressSuggestions } from 'react-dadata';
import {TextInput} from "../TextInput";

interface Props {
	inputId: string
	className?: string
	inputTitle?: string
	value: string
	actionStatus: ACTION_STATUSES
	errorMessage: string
	onInputChange: (value: string, isInputFilled: boolean) => void
	extraProps?: { [key: string]: string | boolean }
	type?: string
	isHideOK?: boolean
}

export const CityInput: React.FC<Props> = props => {
	const [showTitle, setShowTitle] = useState(false)
	const [cityValue, setCityValue] = useState("");

	// Показываем title, если есть автофокус
	useEffect(()=> {
		if (props.extraProps?.autoFocus) {
			setShowTitle(true)
		}
	}, [])

	const checkIfInputFilled = (inputValue: string) => {
		return inputValue.length >= 2
	}

	const handleInputChange = (value: string) => {
		field.current.setCustomValidity("")

		props.onInputChange(value, true)

		value.length >= 0 ? setShowTitle(true) : setShowTitle(false)
	}

	const onBlur = (value: string) => {
		const isFilled = props.isHideOK ? true : checkIfInputFilled(value)

		props.onInputChange(value, isFilled)

		value.length === 0 && setShowTitle(false)
	}

	const onFocus = () => {
		setShowTitle(true)
	}

	const handleBlur = () => {
		cityValue  ? setShowTitle(true) : setShowTitle(false)
		props.onInputChange(cityValue, true)
	}

	const field = useRef(null)

	return (
		<>
			<label
				htmlFor={props.inputId}
				className={classNames(styles.input, props.className, {
					[styles.inputFilled]: props.value.length,
				})}
			>
				<div
					className={classNames(styles.inputContainer, {
						[styles.inputContainer_error]: props.errorMessage,
					})}
				>
					<div className={styles.inputWithTitle}>
						{(props.value || showTitle) && (
							<p className={styles.inputTitle}>{props.inputTitle}</p>
						)}
						<AddressSuggestions
							token="71dacf7b1628edb2942b6985ebe82e9e01677443"
							onChange={setCityValue}
							filterFromBound={"city"}
							filterToBound={"city"}
							filterLocations={[
								{["country_iso_code"]: "KZ"},
								{["country_iso_code"]: "RU"},
								{["country_iso_code"]: "BY"},
								{["country_iso_code"]: "UA"},
								{["country_iso_code"]: "KG"},
								{["country_iso_code"]: "UZ"},
								{["country_iso_code"]: "TJ"},
								{["country_iso_code"]: "GE"},
								{["country_iso_code"]: "AM"},
								{["country_iso_code"]: "AZ"},
								{["country_iso_code"]: "MD"}
							]
						}
							filterRestrictValue={false}
							count={3}
							value={props.value}
							containerClassName={styles.cityInputContainer}
							suggestionsClassName={styles.citySuggestions}
							suggestionClassName={styles.citySuggestions__item}
							ref={field}
							inputProps={{
								'ref': field,
								'className': styles.inputField,
								'placeholder': props.inputTitle || "",
								'onFocus': onFocus,
								'onBlur': handleBlur,
						}
							}
						/>
						{/*{*/}
						{/*	<input*/}
						{/*		ref={field}*/}
						{/*		className={styles.inputField}*/}
						{/*		type={props.type || "text"}*/}
						{/*		id={props.inputId}*/}
						{/*		placeholder={props.inputTitle || ""}*/}
						{/*		value={props.value}*/}
						{/*		onChange={event => {*/}
						{/*			handleInputChange(event.target.value)*/}
						{/*		}}*/}
						{/*		onBlur={event => {*/}
						{/*			onBlur(event.target.value)*/}
						{/*		}}*/}
						{/*		onFocus={() => {*/}
						{/*			onFocus()*/}
						{/*		}}*/}
						{/*		onInvalid={() => handleInvalidField()}*/}
						{/*		disabled={*/}
						{/*			props.actionStatus === ACTION_STATUSES.SENDING ||*/}
						{/*			props.actionStatus === ACTION_STATUSES.SUCCESS*/}
						{/*		}*/}
						{/*		{...props.extraProps}*/}
						{/*	/>*/}
						{/*}*/}
					</div>
					{/*{!props.errorMessage &&*/}
					{/*	checkIfInputFilled(props.value) &&*/}
					{/*	!props.isHideOK && (*/}
					{/*		<div*/}
					{/*			className={classNames(styles.icon, styles.icon_type_trailing)}*/}
					{/*		>*/}
					{/*			<ValidityOkIcon />*/}
					{/*		</div>*/}
					{/*	)}*/}
				</div>

				{props.errorMessage && (
					<p
						className={classNames(
							styles.inputHelperText,
							styles.inputHelperText_type_error
						)}
					>
						{props.errorMessage}
					</p>
				)}
			</label>
		</>
	)
}
