import React, { useState, useRef, useEffect } from 'react'
import classNames from 'classnames'
import styles from './styles.module.scss'
import StepList from '../StepList'
import { SelectInput } from '../SelectInput'
import { CityInput } from '../CityInput'
import { createCloud, sendAdditionalFormData } from '../../../apiRequests/registration'
import { NetError } from '../../../apiRequests/utils'
import pro from '../../../pages-data/discounts/pro'

function BusinessTypeForm(props) {
  const [firstStepFilled, setFirestStepFilled] = useState(false)
  const [isFormFilled, setIsFormFilled] = useState(false)
  const [subStep, setSubStep] = useState(1)

  useEffect(() => {
    const data = {
      name: props.formData.name.value,
      email: props.formData.email.value,
      promoCode: props.formData.promocode.value,
      phone: props.formData.phone.value,
      businessType: props.formData.businessType.value,
      // city: props.formData.city.value.data.city,
      // status: props.formData.status.value,
      // places: props.formData.places.value,
      // smsCode: props.smsCode,
      ycToken: props.ycToken,
    }

    createCloud(data)
      .then((cloudCreateResponse) => {
        props.setCreatedCloudData(cloudCreateResponse)
        props.setIsCloudCreated(true)
      })
      .catch(() => {
        Sentry.captureException(error, (scope) => scope.setTag('source', 'registrationLoader'))

        // Если произошла ошибка на сервере вызвать соответствующий обработчик
        if (error instanceof NetError) {
          props.netErrorHandler()
        }
      })
  }, [])

  useEffect(() => {
    if (Object.values(props.formData).slice(4, 6).every((field) => field.isFilled)) {
      setFirestStepFilled(true)
    } else {
      setFirestStepFilled(false)
    }
  }, [props.formData])

  useEffect(() => {
    if (Object.values(props.formData).slice(4, 8).every((field) => field.isFilled)) {
      setIsFormFilled(true)
    } else {
      setIsFormFilled(false)
    }
  }, [props.formData])

  const handleSubStep = () => {
    sendAdditionalFormData({
      email: props.formData.email.value,
      businessType: props.formData.businessType.value,
      city: props.formData.city.value.data.city,
      status: props.formData.status.value,
      places: props.formData.places.value,
    })
    subStep === 1 ? setSubStep(2) : props.setStep(3)
  }

  return (
    <form className={styles.businessForm}>
      <h2 className={styles.businessForm__title}>
        Расскажите
        {' '}
        <br />
        о вашем бизнесе
      </h2>
      <StepList
        stepsCount={2}
        currentStep={subStep}
        onChangeStep={setSubStep}
      />
      {subStep === 1 && (
        <>
          <SelectInput
            className={styles.businessForm__select}
            inputId="businessType"
            inputTitle="Формат заведения"
            value={props.formData.businessType.value}
            options={[
              'Кофейня',
              'Кафе',
              'Ресторан',
              'Бар',
              'Кальянная',
              'Фастфуд',
              'Фудтрак',
              'Пекарня',
              'Кондитерская',
              'Столовая',
              'Кулинария',
              'Пиццерия',
              'Суши',
              'Дарк китчен',
              'Услуги',
              'Мелкая розница',
              'Другое',
            ]}
            onInputChange={(value, isInputFilled) => props.onChangeFormField('businessType', value, isInputFilled)}
            extraProps={{
              required: true,
              autoFocus: false,
            }}
          />
          <CityInput
            className={styles.businessForm__cityInput}
            inputId="city"
            inputTitle="Город"
            value={props.formData.city.value}
            onInputChange={(value, isInputFilled) => props.onChangeFormField('city', value, isInputFilled)}
            extraProps={{
              required: true,
              autoFocus: false,
            }}
          />

          <button
            className={styles.businessForm__button}
            type="submit"
            onClick={handleSubStep}
            disabled={!firstStepFilled}
          >
            Продолжить
          </button>
        </>
      )}
      {subStep === 2 && (
      <>
        <fieldset className={styles.businessForm__questions}>
          <legend className={styles.businessForm__questionsTitle}>Статус работы заведения</legend>
          <div className={styles.businessForm__radioList}>
            <div className={styles.businessForm__radioContainer}>
              <input
                id="opened"
                onChange={() => props.onChangeFormField('status', 'Уже открыты', true)}
                className={classNames(styles.businessForm__radioInput, styles.visuallyHidden)}
                checked={props.formData.status.value === 'Уже открыты' ? true : undefined}
                type="radio"
                name="status"
              />
              {' '}
              <label className={styles.businessForm__radioItem} htmlFor="opened">Уже открыты</label>
            </div>
            <div>
              <input
                id="process"
                onChange={() => props.onChangeFormField('status', 'В процессе открытия', true)}
                className={classNames(styles.businessForm__radioInput, styles.visuallyHidden)}
                checked={props.formData.status.value === 'В процессе открытия' ? true : undefined}
                type="radio"
                name="status"
              />
              {' '}
              <label className={styles.businessForm__radioItem} htmlFor="process">
                В процессе
                открытия
              </label>
            </div>
            <div>
              <input
                id="plan"
                onChange={() => props.onChangeFormField('status', 'Планирую открыть в будущем', true)}
                className={classNames(styles.businessForm__radioInput, styles.visuallyHidden)}
                checked={props.formData.status.value === 'Планирую открыть в будущем' ? true : undefined}
                type="radio"
                name="status"
              />
              {' '}
              <label className={styles.businessForm__radioItem} htmlFor="plan">
                Планирую открыть в
                будущем
              </label>
            </div>
          </div>
        </fieldset>
        <fieldset className={styles.businessForm__questions}>
          <legend className={styles.businessForm__questionsTitle}>Сколько у вас точек?</legend>
          <div className={styles.businessForm__buttonList}>
            <div>
              <input
                id="1"
                onChange={() => props.onChangeFormField('places', '1', true)}
                className={classNames(styles.businessForm__buttonInput, styles.visuallyHidden)}
                checked={props.formData.places.value === '1' ? true : undefined}
                type="radio"
                name="places"
              />
              {' '}
              <label className={styles.businessForm__buttonItem} htmlFor="1">1</label>
            </div>
            <div>
              <input
                id="2-5"
                onChange={() => props.onChangeFormField('places', '2-5', true)}
                className={classNames(styles.businessForm__buttonInput, styles.visuallyHidden)}
                checked={props.formData.places.value === '2-5' ? true : undefined}
                type="radio"
                name="places"
              />
              {' '}
              <label className={styles.businessForm__buttonItem} htmlFor="2-5">2-5</label>
            </div>
            <div>
              <input
                id="6-10"
                onChange={() => props.onChangeFormField('places', '6-10', true)}
                className={classNames(styles.businessForm__buttonInput, styles.visuallyHidden)}
                checked={props.formData.places.value === '6-10' ? true : undefined}
                type="radio"
                name="places"
              />
              {' '}
              <label className={styles.businessForm__buttonItem} htmlFor="6-10">6-10</label>
            </div>
            <div>
              <input
                id="many"
                onChange={() => props.onChangeFormField('places', 'Более 10', true)}
                className={classNames(styles.businessForm__buttonInput, styles.visuallyHidden)}
                checked={props.formData.places.value === 'Более 10' ? true : undefined}
                type="radio"
                name="places"
              />
              {' '}
              <label className={styles.businessForm__buttonItem} htmlFor="many">Более 10</label>
            </div>
          </div>
        </fieldset>
        <div className={styles.businessForm__bottomButtons}>
          <button
            className={styles.businessForm__backButton}
            type="submit"
            onClick={() => setSubStep(1)}
          >
            Назад
          </button>
          <button
            className={styles.businessForm__registerButton}
            type="submit"
            onClick={handleSubStep}
            disabled={!isFormFilled}
          >
            Зарегистрироваться
          </button>
        </div>
      </>
      )}
    </form>
  )
}

export default BusinessTypeForm
