import React from "react"

import data from "./data"

import styles from "./styles.module.scss"

interface Props {
	setStep: Function
}

function RegistrationFailed(props: Props) {
	return (
		<section className={styles.registrationFailed}>
			{data.img}

			<div className={styles.registrationFailed__content}>
				<h3 className={styles.registrationFailed__title}>{data.title}</h3>

				<div className={styles.registrationFailed__actions}>
					<a
						href={data.support.link}
						target="_blank"
						className={styles.registrationFailed__btnSupport}
						rel="noreferrer"
					>
						{data.support.text}
					</a>

					<button
						type="button"
						className={styles.registrationFailed__btnStepBack}
						onClick={() => props.setStep(1)}
					>
						{data.stepBack.text}
					</button>
				</div>
			</div>
		</section>
	)
}

export default RegistrationFailed
